















































































































import { Vue, Component, Watch } from "vue-property-decorator";
import api from "@/api";
import { SelectListItem, StudentDonorCreateOrUpdateDto, StudentDonorType } from "@/api/appService";
import IndexWithoutTown from "@/components/ProvinceCityArea/indexWithoutTown.vue";
import trainManagementRouter from "@/router/modules/trainManagement";

@Component({
  name: "DonorAdd",
  components: { IndexWithoutTown }
})
export default class DonorAdd extends Vue {
  isLoading = false;
  isEdit = false;
  formData: StudentDonorCreateOrUpdateDto = {
    name: "",
    type: "" as StudentDonorType | undefined,
    provinceId: 0,
    cityId: 0,
    areaId: 0,
    address: "",
    contactName: "",
    named: "",
    contactPhone: "",
    contactEmailAddress: "",
    remark: ""
  };
  labelPosition = "top";
  rules = {
    name: [{ required: true, message: "请输入捐助方名称", trigger: "blur" }],
    type: [{ required: true, message: "请选择捐助方类型", trigger: "change" }],
    contactName: [{ required: true, message: "请输入联系人名称", trigger: "blur" }],
    contactPhone: [{ required: true, message: "请输入联系人手机号", trigger: "blur" }, {
      validator: this.checkPhone,
      trigger: ["blur", "change"]
    }],
    contactEmailAddress: [{ required: true, message: "请输入邮箱", trigger: "blur" }, {
      type: "email",
      message: "请输入正确的邮箱地址",
      trigger: ["blur", "change"]
    }]
  };
  StudentDonorTypeList: SelectListItem[] = [];

  async created() {
    if (this.$route.params.donorId) {
      this.isEdit = true;
      this.formData = await api.donor.get({ id: Number(this.$route.params.donorId) });
    }
    this.StudentDonorTypeList = await this.getStudentDonorTypeList();
  }

  getStudentDonorTypeList() {
    return api.enumService.getValues({ typeName: "StudentDonorType" });
  }

  checkPhone(rule: any, value: any, callback: any) {
    if (!value) {
      return callback(new Error("手机号不能为空"));
    } else {
      const reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    }
  }

  onSubmit(formName: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this.$refs[formName]!.validate((valid: unknown) => {
      if (valid) {
        this.isLoading = true;
        if (!this.isEdit) {
          api.donor.create({ body: this.formData }).then(() => {
            this.$message.success("提交成功");
            this.isLoading = false;
            this.back();
          }).catch(() => {
            this.$message.error("提交失败");
            this.isLoading = false;
          });
        } else {
          api.donor.update({ body: this.formData }).then(() => {
            this.$message.success("提交成功");
            this.isLoading = false;
            this.back();
          }).catch(() => {
            this.$message.error("提交失败");
            this.isLoading = false;
          });
        }
      } else {
        this.$message.error("提交失败");
        this.isLoading = false;
        return false;
      }
    });
  }

  back() {
    this.$router.go(-1);
  }
}
